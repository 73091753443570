<template>
  <div>
    <div class="intro-container">
      <div class="container-1000">
        <div class="intro-section">
          <h1 class="text-center">
            Schuette® Plissees, Rollos & Vorhänge zum Verlieben für Ihr Zuhause!
          </h1>

          <div class="grid-1-2-1 category-intro-photos category-intro-videos">
            <div class="grid-item">
              <!-- <lazy-video :video="{video: RelaxVideoTwo}" alt="willkommen" /> -->
              <b-image
                src="https://img.schuette.shop/schuette/categories/rollos/schuette-fabrics-1.jpg.webp"
                webp-fallback="https://img.schuette.shop/schuette/categories/rollos/schuette-fabrics-1.jpg"
                class="skeleton-image-placeholder"
                lazy
                alt="rollos"
              />
            </div>
            <div class="grid-item">
              <lazy-video :video="{video: WelcomeVideoTwo}" alt="willkommen" />
              <!-- <div class="grid-item-content">
                <div class="category-link-new">
                  {{ $t('project.landingPage.new') }}
                </div>
                <a :href="getSLLink">
                  <b-button type="is-primary" class="cta-primary" expanded rounded icon-right="arrow-right-bold">
                    {{ $t('project.landingPage.browseAll') }}
                  </b-button>
                </a>
              </div> -->
            </div>
            <div class="grid-item">
              <b-image
                src="https://img.schuette.shop/schuette/categories/pleated-blinds/schuette-fabrics-3.jpg.webp"
                webp-fallback="https://img.schuette.shop/schuette/categories/pleated-blinds/schuette-fabrics-3.jpg"
                class="skeleton-image-placeholder"
                lazy
                alt="plissee"
              />
            </div>
          </div>

          <div class="check-list-container">
            <ul class="check-list is-big">
              <li><b>Unkompliziert</b> - Konfigurieren und bestellen Sie Ihre Plissees, Rollos, Vorhänge, Dekokissen & Spannbettlaken direkt online</li>
              <li><b>Einfach</b> - Wählen Sie Montageart, Stoff, Profil & Größe in wenigen Sekunden</li>
              <li><b>Service</b> - Chat, E-Mail oder direkt bei Ihnen vor Ort. Wir sind immer für Sie da!</li>
              <li><b>Schnell</b> - Sehen Sie direkt auf der Produktseite des Plissees wann wir liefern</li>
              <li><b>Transparent</b> - Alle Preise sind direkt sichtbar!<br><span style="font-weight: bold; color: #D20311;">Die Versandkosten übernehmen wir</span> ❤️</li>
            </ul>
          </div>

          <h2 class="text-center">
            Willkommen bei der Plissee, Rollo & Heimtextilien Manufaktur Schuette®
          </h2>

          <div class="text-container">
            <p class="text-center">
              Wir möchten Ihnen einfach nur Danke sagen! Danke für Ihre Unterstützung, sowie Ihre Wertschätzung unserer Arbeit & Herzblutes, welches wir in die Entwicklung und Produktion Ihrer Plissees, Rollos und Vorhänge investieren.
            </p>
            <p class="text-center">
              Sie geben uns unglaublich viel Kraft und wir möchten uns mit den bestmöglichen & mit Leidenschaft hergestellten Produkten hierfür bei Ihnen herzlich bedanken!
            </p>
          </div>

          <categories v-if="page" :categories="page.navigation" home full>
            <template #features="{identifier}">
              <!-- plisy -->
              <template v-if="identifier === 'plisy'">
                <div class="category-feature">
                  <div class="feature-icons">
                    <quick-icon />
                    <drilling-icon />
                    <no-drilling-icon />
                  </div>
                  <div class="feature-description">
                    Schnelle 60s-Montage (Mit oder Ohne Bohren)
                  </div>
                </div>
                <div class="category-feature">
                  <div class="feature-icons">
                    <collections-icon />
                    <colors-icon />
                  </div>
                  <div class="feature-description">
                    5 Kollektionen & 74 Farben
                  </div>
                </div>
                <div class="category-feature">
                  <div class="feature-icons">
                    <check-icon />
                    <strong-icon />
                  </div>
                  <div class="feature-description">
                    Schuette® Qualitätsversprechen
                  </div>
                </div>
                <div class="category-feature">
                  <div class="feature-description">
                    Ab 27,99 EUR <br> Kostenloser Versand
                  </div>
                </div>
              </template>

              <!-- roletki -->
              <template v-if="identifier === 'roletki'">
                <div class="category-feature">
                  <div class="feature-icons">
                    <quick-icon />
                    <drilling-icon />
                    <no-drilling-icon />
                  </div>
                  <div class="feature-description">
                    Schnelle 60s-Montage (Mit oder Ohne Bohren)
                  </div>
                </div>
                <div class="category-feature">
                  <div class="feature-icons">
                    <collections-icon />
                    <colors-icon />
                  </div>
                  <div class="feature-description">
                    2 Kollektionen & 26 Farben
                  </div>
                </div>
                <div class="category-feature">
                  <div class="feature-icons">
                    <check-icon />
                    <strong-icon />
                  </div>
                  <div class="feature-description">
                    Schuette® Qualitätsversprechen
                  </div>
                </div>
                <div class="category-feature">
                  <div class="feature-description">
                    Ab 9,99 EUR <br> Kostenloser Versand
                  </div>
                </div>
              </template>

              <!-- zaslony -->
              <template v-if="identifier === 'zaslony'">
                <div class="category-feature">
                  <div class="feature-icons">
                    <quick-icon />
                  </div>
                  <div class="feature-description">
                    Schnelle 60s-Montage (Mit Ösen oder Gardinenband)
                  </div>
                </div>
                <div class="category-feature">
                  <div class="feature-icons">
                    <collections-icon />
                    <colors-icon />
                  </div>
                  <div class="feature-description">
                    2 Kollektionen & 30 Farben
                  </div>
                </div>
                <div class="category-feature">
                  <div class="feature-icons">
                    <check-icon />
                    <strong-icon />
                  </div>
                  <div class="feature-description">
                    Schuette® Qualitätsversprechen
                  </div>
                </div>
                <div class="category-feature">
                  <div class="feature-description">
                    Ab 42,99 EUR <br> Kostenloser Versand
                  </div>
                </div>
              </template>
            </template>
          </categories>
        </div>
      </div>
    </div>
    <div class="company-container">
      <div class="container-1000">
        <div class="company-section">
          <h2>
            Liebe Kundin, lieber Kunde ❤️,
          </h2>
          <p>
            vielen Dank, dass Sie sich die Zeit nehmen, mehr über uns und unsere Produkte in Erfahrung zu bringen.
          </p>
          <p class="font-semibold">
            Schuette® Plissees, Rollos und Sonnenschutzsysteme werden lokal unter Einhaltung strengster Handwerkskunst und zu fairen Löhnen wie auch aus soliden, langlebigen & modernen Materialien hergestellt.
          </p>
          <p>
            Wir sind eine kleine Manufaktur in Familienhand und wir nutzen, entwickeln & leben unsere Produkte tagein, tagaus. Unser kleines Team setzt alles daran, damit unsere Plissee-Rollos und Vorhänge nicht nur den höchsten Handwerkskunst-Standards gerecht werden, sondern vor allem den Ansprüchen in Ihrem Zuhause, denn wir verstehen was, "Zuhause" heißt und was es für Sie bedeutet. Ein Plus an Lebensqualität durch formvollendetes Design und überlegene Funktion am Fenster ist unser Anspruch.
          </p>
          <div class="grid-3n category-intro-photos">
            <div class="grid-item">
              <b-image
                src="https://img.schuette.shop/schuette/homepage/schuette-company-1.jpg.webp"
                webp-fallback="https://img.schuette.shop/schuette/homepage/schuette-company-1.jpg"
                class="skeleton-image-placeholder"
                lazy
                alt="Schuette®"
              />
            </div>
            <div class="grid-item">
              <b-image
                src="https://img.schuette.shop/schuette/homepage/schuette-company-2.jpg.webp"
                webp-fallback="https://img.schuette.shop/schuette/homepage/schuette-company-2.jpg"
                class="skeleton-image-placeholder"
                lazy
                alt="Schuette®"
              />
            </div>
            <div class="grid-item">
              <b-image
                src="https://img.schuette.shop/schuette/homepage/schuette-company-3.jpg.webp"
                webp-fallback="https://img.schuette.shop/schuette/homepage/schuette-company-3.jpg"
                class="skeleton-image-placeholder"
                lazy
                alt="Schuette®"
              />
            </div>
          </div>
          <h2 class="text-center">
            Schuette® Qualitäts&shy;versprechen
          </h2>
          <p>
            Wir sind besonders stolz darauf, dass wir jedes kleinste Einzelteil all unserer Schuette® Produkte selbst entwickeln und somit die gesamte Herstellung in der eigenen Hand halten. Dies ermöglicht es uns, auf jedem einzelnen Schritt strenge Qualitätsstandards zu wahren.
          </p>
          <p>
            Im Vordergrund steht dabei eine außerordentliche Strapazierfähigkeit und Langlebigkeit aller Komponenten sowie eine unkomplizierte Anwendung bei Ihnen Zuhause. All dies unterstreichen wir mit unserem authentischen Schuette® Qualitätsversprechen:
          </p>
          <div class="check-list-container">
            <ul class="check-list is-big">
              <li><b>Einfache Anwendung</b> - Durch passende Befestigungen für jedes Fenster</li>
              <li><b>Erwiesene Langlebigkeit</b> - 100.001 Mal in den verschiedensten Konfigurationen und unter allen klimatischen Bedingungen getestet</li>
              <li><b>Pflegeleicht</b> - Für ein sorgloses “danach”</li>
              <li><b>UV-Schutz</b> - Um ein Verblassen der Stoffe & Ihrer Möbel zu vermeiden</li>
              <li><b>Immer zeitgemäß</b> - Mit Fokus auf ständiger Weiterentwicklung & Optimierung</li>
              <li><b>Rundumservice</b> - Immer für Sie da, egal wann, egal wie.</li>
            </ul>
          </div>
          <div class="grid-1-2-1">
            <div class="grid-item">
              <b-image
                src="https://img.schuette.shop/schuette/homepage/schuette-quality-1.jpg.webp"
                webp-fallback="https://img.schuette.shop/schuette/homepage/schuette-quality-1.jpg"
                class="skeleton-image-placeholder"
                lazy
                alt="Schuette Vorhänge"
              />
            </div>
            <div class="grid-item">
              <b-image
                src="https://img.schuette.shop/schuette/homepage/schuette-quality-2.jpg.webp"
                webp-fallback="https://img.schuette.shop/schuette/homepage/schuette-quality-2.jpg"
                class="skeleton-image-placeholder"
                lazy
                alt="Schuette Plissee"
              />
            </div>
            <div class="grid-item">
              <b-image
                src="https://img.schuette.shop/schuette/homepage/schuette-quality-3.jpg.webp"
                webp-fallback="https://img.schuette.shop/schuette/homepage/schuette-quality-3.jpg"
                class="skeleton-image-placeholder"
                lazy
                alt="Schuette Rollos"
              />
            </div>
          </div>

          <h2 class="text-center ">
            Schuette® - Das Original
          </h2>
          <p class="font-semibold is-big">
            Liebe Kunden,
          </p>
          <p>
            <b> Das was wir machen, kann man nicht kopieren</b>. Unsere Schuette® Produkte sind ein Resultat von jahrelanger Hingabe zum Detail und der fortwährenden Weiterentwicklung unserer einzigartigen Technologien.
          </p>
          <p>
            Trotz unverkennbarer Merkmale, die Schuette® Produkte zu unvergleichbaren Originalen machen, gibt es immer mehr Nachahmer, die versuchen, unseren Erfolg zu imitieren. Wir versuchen es als Kompliment zu sehen, dass unsere harte Arbeit eine Inspirationsquelle für andere darstellt.
          </p>
          <p>
            Dennoch sollten sie verstehen, dass es nicht ausreicht, das Etikett "Premium" zu verwenden oder unsere Bilder und Designs zu kopieren. Ebenso wenig genügt es, unsere hochwertigen Halterungen in minderer Qualität in China kopieren zu lassen und dann als "Made in Europe" zu deklarieren.
            Fälscher versuchen, auf dem Rücken von Mittelständlern und Familien-Manufakturen wie unserer, minderwertige Produkte zu verkaufen.
          </p>
          <p>
            Doch eine Fälschung bleibt stets eine Fälschung. Das Original kann nicht reproduziert werden, denn unsere Leidenschaft, Energie und Hingabe, die wir in die Entwicklung, Produktion, den Service und den direkten Kontakt mit Ihnen stecken, sind einzigartig.
          </p>
          <p>
            <b> Warum?</b> Weil wir buchstäblich das leben, was wir schaffen - Tag für Tag. Dank Ihnen, den besten Kunden der Welt, werden wir jeden Tag motivierter, noch fleißiger zu sein.
          </p>
          <p>
            Liebe Kunden, bitte setzen Sie weiterhin auf Qualität, wenn es um Ihr Zuhause geht. Setzen Sie auf uns, auf das Original, auf die Schuette® Familien-Manufaktur und auf Menschen, die ihr Leben dafür widmen, Produkte zu schaffen, die Ihr Zuhause bereichern.
          </p>

          <h2 class="text-center">
            Wir als kleines Familien&shy;unternehmen wissen was “Zuhause” heißt
          </h2>
          <p>
            Deswegen entwickeln und produzieren wir unsere Produkte nicht nur unter Einhaltung höchster Qualitätsstandards in Fertigung und Material, sondern vor allem, um Ihren Standards und Wünschen als Nutzer gerecht zu werden, denn wir schätzen und respektieren Ihr "Zuhause".
          </p>
          <div class="company-image">
            <b-image
              src="https://img.schuette.shop/schuette/homepage/de/schuette-unique.jpg.webp"
              webp-fallback="https://img.schuette.shop/schuette/homepage/de/schuette-unique.jpg"
              class="skeleton-image-placeholder is-hidden-mobile"
              lazy
              alt="Schuette Bedeutung"
            />
            <b-image
              src="https://img.schuette.shop/schuette/homepage/de/schuette-unique-mobile.jpg.webp"
              webp-fallback="https://img.schuette.shop/schuette/homepage/de/schuette-unique-mobile.jpg"
              class="skeleton-image-placeholder is-hidden-tablet mobile-unique"
              lazy
              alt="Schuette Bedeutung"
            />
          </div>
          <p>
            <b>Niemand sucht nach sogenannten Baumarkt-Plissees oder China-Rollos</b>,  doch leider landete diese Art von Produkten in der Vergangenheit bisher zu häufig im Einkaufswagen vieler Käufer, da qualitativ höherwertige Produkte preislich oft einfach zu sehr abstanden.
          </p>
          <p>
            <b>Dieses Problem lösen wir als Produzent nun mit der Öffnung zum direkten Verkauf an Sie als Endkunden</b> und nicht nur exklusiv an Architekten, Hotels, Restaurants & renommierte Montagefirmen für Sonnenschutzsysteme wie bisher. Bitte glauben Sie uns, wenn wir sagen, dass uns dies Tag für Tag unglaubliche Freude bereitet ❤️
          </p>
          <p>
            Es wäre unserer Familie und unserem Team eine Ehre, wenn unsere Produkte in Ihrem Zuhause mit den Sonnenstrahlen spielen und Sie sich von ihnen verzaubern lassen würden.
          </p>
          <p><b>Liebe Grüsse,</b></p>
          <div class="schuette-family">
            <logo-schuette class="family-logo" />Familie Schuette®
          </div>
        </div>
      </div>
    </div>
    <div class="products-highlights">
      <div class="container-1000">
        <mini-categories v-if="page" :categories="page.navigation" home />
      </div>
    </div>
  </div>
</template>

<script>
import LazyVideo from 'marketplace-front-core/components/shared/LazyVideo.vue'
import Categories from '../landing/Categories.vue'
import MiniCategories from '../landing/MiniCategories'
import faq from './faq-de.js'

import LogoSchuette from '~/static/images/logo-schuette.svg?inline'
import NoDrillingIcon from '~/assets/images/homepage/nodrilling-square.svg?inline'
import DrillingIcon from '~/assets/images/homepage/drilling-square.svg?inline'
import QuickIcon from '~/assets/images/homepage/quick-outline-square.svg?inline'
import CollectionsIcon from '~/assets/images/homepage/collections-outline-square.svg?inline'
import ColorsIcon from '~/assets/images/homepage/colors-outline-square.svg?inline'
import StrongIcon from '~/assets/images/homepage/strong-outline-square.svg?inline'
import CheckIcon from '~/assets/images/homepage/check-square.svg?inline'

import WelcomeVideoTwo from '~/static/images/schuette-welcome-video-2.mp4'

export default {
  name: 'HomePageEn',
  components: {
    LazyVideo,
    Categories,
    MiniCategories,
    NoDrillingIcon,
    DrillingIcon,
    QuickIcon,
    CollectionsIcon,
    ColorsIcon,
    StrongIcon,
    CheckIcon,
    LogoSchuette
  },
  props: {
    page: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      WelcomeVideoTwo
    }
  },
  head () {
    return {
      script: [{ type: 'application/ld+json', json: this.getFaqSchemaOrg }]
    }
  },
  computed: {
    getFaqSchemaOrg () {
      const entity = faq.map((x) => {
        return {
          '@type': 'Question',
          name: x.question.replace(/["]+/g, '&quot;'),
          acceptedAnswer: {
            '@type': 'Answer',
            text: x.answer.replace(/["]+/g, '&quot;')
          }
        }
      })
      const schema = {
        '@context': 'https://schema.org',
        '@type': 'FAQPage',
        mainEntity: [...entity]
      }
      return schema
    },
    getSLLink () {
      return this.localePath({
        name: 'product',
        params: {
          productName: this.toSlugTitle({ name: this.getSLName }),
          productId: 66837
        }
      })
    },
    getSLName () {
      switch (this.currentLang) {
        // LINK_PRODUKCYJNY
        case 'en':
          return 'schuette-fitted-sheet-with-all-round-elastic-wow-collection-white-90-x-200-x-20-cm'
        case 'fr':
          return 'schuette-drap-housse-avec-elastique-circulaire-collection-wow-blanc-90-x-200-x-20-cm'
        case 'es':
          return 'sabana-bajera-schuette-con-elastico-integral-wow-coleccion-blanco-90-x-200-x-20-cm'
        case 'it':
          return 'lenzuolo-teso-schuette-con-elastico-integrale-wow-collezione-bianco-90-x-200-x-20-cm'
        default:
          return 'schuette-spannbettlaken-mit-rundumgummi-90-x-200-x-20-cm-wow-kollektion-weis'
      }
    }
  }
}
</script>
